import './about.css'
import img from '../../../img/7.png'
import quote from '../../../img/5.png'
import cam from '../../../img/14.png'
import second from '../../../img/321.png'
import { Button, CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TeamMemberListing } from './AboutReducer'
import { useNavigate } from 'react-router-dom'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VideocamIcon from '@mui/icons-material/Videocam';
import { projectListingCount } from '../../Backend/Projects/ProjectReducer'


function About() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ListingData= useSelector((state)=> state.aboutPageSlice?.teamListingData? state.aboutPageSlice?.teamListingData: [] )
    const projectCount= useSelector((state)=> state.productSlice?.count? state.productSlice?.count: 0 )
    const loader= useSelector((state)=> state.aboutPageSlice?.loading? state.aboutPageSlice?.loading: false)

    
    useEffect(() => {
        dispatch(TeamMemberListing({}));
        dispatch(projectListingCount({}));
    },[])
    

    return (
        <>
            <div className='maincontainer_aboutpage'>

                
                {/* ------ First Top Section ----- */}
                <div className='topSection'>
                    <div className='heading'>
                        <h1 className='head'> DREAMLINER ENTERTAINMENT</h1>
                        <p className='para'>
                        Dreamliner Entertainment, an illustrious production company, based in Kolkata, was founded by the visionary Arindam Bhattacharya. Passionate about films, Arindam stands as the driving force behind all three of his cinematic masterpieces. He has been constantly working on adding new feathers to the hat, under the directory of films bannered by Dreamliner Entertainment. The team comprises of some of the most exceptional talents from the film industry, across diverse fields. The names make a collective brilliance that has been celebrated with prestigious national awards. At Dreamliner Entertainment, the team takes immense pride in their reputation for excellence, which involve flawless project execution, bewildering onscreen outputs and unwavering professionalism.  To Dreamliner Entertainment, Quality is the Pilot to Fly the Creative Aircraft.
                        </p>
                    </div>

                    <div className='topsection_secondblock'>
                        <div className='leftBlock'>

                            <div className='blocks'> 
                               <p className='para'>
                            Arindam's extensive experience in the corporate realm has provided us with a unique, world-class approach to the entire production process. This invaluable perspective ensures that every project we undertake is executed with precision and finesse.
                                </p>


                                <p className='para'>
                                                        
                                Our journey is defined by creativity, dedication, and a relentless pursuit of perfection. We invite you to explore our world of captivating storytelling, where every production is a testament to our passion for the art of cinema.
                                </p>

                                <p className='para'>
                            
                                With an unwavering commitment to excellence, Dreamliner Entertainment continues to shape the future of entertainment, one remarkable project at a time. Join us on our cinematic journey and be a part of something extraordinary.
                                </p>
                            </div>

                            <div className='blocks vision'> 
                                <div className='vissonnMission'>
                                    <h2> OUR VISION</h2>
                                    <p> Dreamliner Entertainment, founded by Arindam, drives cinematic innovation. Our exceptional team, celebrated with national awards, delivers excellence through flawless execution, professionalism, and efficiency. Arindam's corporate acumen enriches every project, executed with finesse. Our journey is defined by creativity and a relentless pursuit of cinematic perfection. Join us in shaping the future of entertainment. </p>
                                </div>

                                <div className='vissonnMission'>
                                    <h2> OUR MISSION</h2>
                                    <p> At Dreamliner Entertainment, our mission is to be the driving force behind exceptional cinematic experiences. Founded by Arindam, we unite industry-leading talents across diverse fields to create masterpieces celebrated with prestigious national awards. We are synonymous with excellence, professionalism, and precision, shaping the future of entertainment through our unwavering commitment to cinematic artistry. </p>
                                </div>
                            </div>

                            <div className='blocks imgSection'>
                                <img src={second} alt="Directors Image" className='director_img'/>
                            </div>
                        </div>



                    </div>


                </div>
                {/* ------ End First Top Section ----- */}

                

                {/* ------ Second Section ----- */}
                <div className='second_section'>
                    <div className='one'>
                    <img src={cam} alt='' className='icon'/>
                    </div>
                    <div className='two'>
                        <h2> OUR SERVICES </h2>
                        <p> Explore Our Comprehensive Range of Expertly Crafted Services Today </p>
                        
                        <p> We thrive on unique ideas, highly engaging scripts and shows for the new-age audience. </p>

                        <p>

                            Our film production company offers services for films, documentaries, explainer videos, short-films, social media videos, animated videos, corporate videos. If you are looking for film production houses in Kolkata, then you need to look no further, we are just a call away/ mail away.
                        </p>
                    </div>

                    <div className='three'>
                        <img src={quote} alt='' className='icon'/>
                        <p> Arindam, a versatile filmmaker, has made his mark with a diverse portfolio. He introduced "Antarleen" in 2016, a murder mystery set in Himachal Pradesh. His second creation, "Flat no 609" (2018), is renowned in Bengali horror. Arindam's interview series "Ochena Manik" features insights from Satyajit Ray's actors. He ventured into shorts with "Oporichito" and crafted "Antardhaan" in 2021, followed by the unique "Shibpur" in 2023. </p>
                        <p> Currently, his new investigative thriller, "Durgapur Junction," is in post-production, set for a 2024 release.</p>
                       

                        <h2> ARINDAM BHATTACHARYA </h2>
                        <p> Founder,Director, Script-Writter, Producer</p>

                    </div>
                    
                </div>
                {/* --------------------------- */}



                {/* ------ Third Section ------- */}
                <div className='third_section'>

                    <div className='action'>
                        <h2> LET'S CONNECT </h2>
                        <p>
                           Reach Out to Us for Inquiries, Collaborations, or Any Questions. We're Here to Help
                        </p>
                        
                        <Button variant="contained" className="button" onClick={()=>navigate('/contact')}>CONTACT US</Button>
                    </div>
                </div>
                {/* ------------------------------- */}



                {/* ------ Fourth Section ------- */}
                 <div className='fourth_section'>

                    <div className='one'>
                        <div className='awards'>
                        <h2> <EmojiEventsIcon className='newIcn'/> AWARDS </h2>
                        <p>Discover Our Recognition in Excellence and Celebrated Achievements in Various Fields </p>

                        </div>

                        <div className='projects'>
                        <h2 onClick={()=> navigate('/projects')}> <VideocamIcon className='newIcn'/> &nbsp; PROJECTS </h2>
                            <p>  Explore Our Portfolio of Diverse and Successful Projects </p>
                            <h5> {projectCount}+ </h5>
                        </div>
                    </div>

                    <div className='imgBlock'>
                        <img src={img} alt='' className='bigImg'/>
                    </div>
                 </div>
                {/* ---------------------------- */}
                

                 {/* ------ Fifth Section ------- */}
                {loader===false?<div className='fifth_section'>

                    <div className='heading'>
                        <h1> OUR TEAM </h1>
                        <p> Meet Our Dedicated and Expert Team Driving Success and Innovation </p>
                    </div>

                    
                    {loader === false && ListingData.length>0 ?    <div className='container'>
                        <div className='team_block'>
                            <h2>  Founder, Director & Producer</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "founders" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>


                        <div className='team_block'>
                            <h2> Producer & Partner</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "producer_partner" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                       


                        <div className='team_block'>
                            <h2> PR and Media Relations</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "pr" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                        <div className='team_block'>
                            <h2> Production Designer</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "production_designer" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>


                        <div className='team_block'>
                            <h2> Film Strategist</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "film_strategist" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                        <div className='team_block'>
                            <h2> Social Media Specislist </h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "social_Media_specislist" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>


                        <div className='team_block'>
                            <h2> Branding </h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        (x.designation === "branding" && x.status===true) &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>


                        <div className='team_block'>
                            <h2>  Graphics and VFX </h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "graphics_vfx" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                        <div className='team_block'>
                            <h2> Executive Producer </h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "executive_producer" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                        <div className='team_block'>
                            <h2>Look Specialist</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "look_specialist" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                        <div className='team_block'>
                            <h2>Costume Designer  </h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "costume_designer" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>


                       


                        <div className='team_block'>
                            <h2>Production Controller </h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "production_controller" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                        <div className='team_block'>
                            <h2> Director Of Photography </h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "dop" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}

                            </div>
                        </div>

                        <div className='team_block'>
                            <h2> Chief Asstt Director</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        (x.designation === "chief_ast_director" && x.status === true) &&
                                            (<div className='cards'>
                                                <img src={x.image} alt="teammate image" className='card-img' />
                                                <h3>{x.name}</h3>
                                            </div>) )
                                })}

                            </div>
                        </div>
                       
                        <div className='team_block'>
                            <h2> Asstt Director</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        (x.designation === "asstt_director" && x.status === true) &&
                                            (<div className='cards'>
                                                <img src={x.image} alt="teammate image" className='card-img' />
                                                <h3>{x.name}</h3>
                                            </div>) )
                                })}

                            </div>
                        </div> 

                        <div className='team_block'>
                            <h2> Editor</h2>
                            <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        (x.designation === "editor" && x.status === true) &&
                                            (<div className='cards'>
                                                <img src={x.image} alt="teammate image" className='card-img' />
                                                <h3>{x.name}</h3>
                                            </div>) )
                                })}

                            </div>
                        </div> 

                    </div> : <div className='team_block'> <p> No Data Found!</p> </div>}

                </div> : <div class="loader_section"><CircularProgress /> </div>}
                
                {/* -------------------------------- */}
                


                {/* -------- Sixth Block -------- */}
                {loader === false ?
                
                    <div className='sixth_block'>

                    <div className='heading'>
                        <h1> OUR PARTNERS </h1>
                        <p> Explore Our Trusted Partnerships Powering Collaborative Excellence and Mutual Success </p>
                        
                        </div>
                        
                        {loader === false && ListingData.length > 0 &&
                            <div className='container'>
                                  <div className='container'>

                                {ListingData.map((x) => {
                                    return (
                                        x.designation === "partner" && x.status===true &&
                                        (<div className='cards'>
                                            <img src={x.image} alt="teammate image" className='card-img' />
                                            <h3>{x.name}</h3>
                                        </div>))
                                })}
                                        
                            </div>
                        </div>}

                            
                            

                            
                    </div> : <div class="loader_section"><CircularProgress /> </div>
                    
                }
                {/* ------------------------------------ */}
                
            </div>
        </>
    );
}


export default About;


// Director


