import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const login = createAsyncThunk('user/login', async (body) => {
  // console.log('body====>', body)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}login`, requestOptions);
  const res = await response.json();
  return res;
})


export const getipInfo = createAsyncThunk("user/getipInfo", async () => {
  // console.log("process.env==>", process.env);

  const ipresponse = await fetch(process.env.REACT_APP_API_URL_IP);
  const ipInfo = await ipresponse.json();
  // console.log('ipInfo==>', ipInfo);
  // console.log('ipresponse==>', ipresponse);
  ipInfo.browser = getbrowserinfo();
  return ipInfo;
});

export const logout = createAsyncThunk('user/logout', async (body) => {
  // console.log('body====>', body)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}logout`, requestOptions);
  const res = await response.json();
  return res;
})



const loginSlice = createSlice({
  name: 'loginSlice',
  initialState: {
    ipInfo: {},
    userInfo: {},
    access_token: null,
    _id: null,
    isLoggedIn: false,
    loading: false,
    snackFlag: false,
    snackMessage: null,
    snackType: null,

  },
  reducers: {

    setLogedinUserInfo(state, action) {
      if (action.payload !== undefined && action.payload.access_token != null && action.payload.userInfo != null) {
        state.userInfo = action.payload.userInfo;
        state._id = action.payload.userInfo._id;
        state.access_token = action.payload.access_token;
        state.isLoggedIn = true;
      }
    },

    resetSnackbar(state, action) {
      state.snackFlag = false;
      state.snackMessage = null;
      state.snackType = null
    }




  },
  extraReducers: {
    [getipInfo.fulfilled]: (state, action) => {
      state.ipInfo = action.payload;
    },
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.snackFlag = true;
      state.loading = false;
      if (action.payload.status == 'success') {
        state.access_token = action.payload.response.accessToken;
        state.userInfo = action.payload.response.userData;
        state._id = action.payload.response.userData._id;
        state.isLoggedIn = true;
        state.snackMessage = action.payload.response.message
        state.snackType = 'success';
      }
      if (action.payload.status == 'error') {
        state.isLoggedIn = false;
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
    },


    [logout.pending]: (state, action) => {
      state.loading = true;
    },
    [logout.fulfilled]: (state, action) => {
      state.loading = false;
      state.snackFlag = true;
      if (action.payload.status == 'success') {
        state.access_token = null;
        state.userInfo = {};
        state._id = null;
        state.isLoggedIn = false;
        state.snackMessage = action.payload.response.message
        state.snackType = 'success';
      }
      if (action.payload.status == 'error') {
        state.isLoggedIn = false;
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [logout.rejected]: (state, action) => {
      state.loading = false;
    },
  }
})



export const { setLogedinUserInfo, resetSnackbar } = loginSlice.actions;
export default loginSlice.reducer;

function getbrowserinfo() {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    return ('Opera');
  }
  else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return ('Edge');
  }
  else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return ('Chrome');
  }
  else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return ('Safari');
  }
  else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return ('Firefox');
  }
  else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) //IF IE > 10
  {
    return ('IE');
  }
  else {
    return ('unknown');
  }
}
