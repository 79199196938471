
import './contact.css'
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import BusinessIcon from '@mui/icons-material/Business';


function Contact() {

    return (
        <>
            
            <div className="maincontainer_contact">

                <div className="banner_section">
                    <h2> CONTACT US</h2>
                    <p> 
                    Get in touch with us for inquiries, assistance, or collaboration.
                   </p>
                </div>

                <div className='secondSection'>
                    <div className='cards'>
                        <p className='headingone'> <AttachEmailIcon  className='icons'/> Email </p>
                         <p> producer@dreamlinerentertainment.com </p>
                        

                        <div className="headingTwo">
                            <p className='content'> <BusinessIcon className='icons' /> Registered  Office </p>
                            <p> C1102 Bestech Sanskruti Sector 92 Gurgaon 122505</p>
                        </div>
                        
                    </div>

                    <div className='cards'>
                        
                        <div className="heading">
                            <p className='headingone'> <BusinessIcon className='icons' /> Branch Office </p>
                        </div>

                        <div className='headingTwo'>
                            <h4> Canada</h4>
                            <p>365 Pioneer Drive Kitchener Ontario - N2P 2A6</p>
                        </div>
                        
                        <div className='headingTwo'>
                            <h4> Kolkata</h4>
                            <p> 15 Mayfair Road Kolkata 700019 </p>
                       </div>

                    </div>

                </div>


            </div>
        </>
    );
    
}

export default Contact;



