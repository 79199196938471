import React from 'react'
import './backendheader.css';

import DashboardIcon from '@mui/icons-material/Dashboard';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Groups2Icon from '@mui/icons-material/Groups2';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Tooltip } from '@mui/material';
import MovieIcon from '@mui/icons-material/Movie';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';



const BackendHeader = (props) => {

  const navigate = useNavigate();

  return (
    <div className='backendHeader'>

      <ul className='menu'>
        <li className='item'>
          <Tooltip title="Dashboard" placement="left-end">
            <DashboardIcon onClick={()=> navigate('/dashboard')} />
          </Tooltip>
              </li>
              
        <li className='item'>
            <Tooltip title="Add Projects" placement="left-end">
                <MovieIcon onClick={()=> navigate('/add-project')} />
            </Tooltip>
        </li>


        <li className='item'>
          <Tooltip title="Add Team Memebers" placement="left-end">
            <Groups2Icon onClick={ ()=> navigate('/add-member')} />
          </Tooltip> 
        </li>

        <li className='item'>
          <Tooltip title="Artist Directory" placement="left-end">
            <TheaterComedyIcon onClick={()=> navigate('/add-artist')}/>
          </Tooltip>
        </li>

      </ul>

  

      <ul className='menu'>
        <li className='item'>
                        {/* <Tooltip title="Profile" placement="left-end"> */}
                        {/* <MoreVertIcon />  */}
                        <HomeIcon onClick={() => navigate('/')} />
                        {/* <AccountCircleIcon /> */}
                        {/* </Tooltip> */}
                    </li>

                    
        {/* <li className='item'> */}
          {/* <Tooltip title="Profile" placement="left-end"> */}
           {/* <MoreVertIcon/>  */}
           {/* <AccountCircleIcon /> */}
          {/* </Tooltip> */}
        {/* </li> */}
      </ul>

      

    </div>
  )
}

export default BackendHeader