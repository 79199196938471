import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const memberList = createAsyncThunk('user/list-member', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}team-management/list`, requestOptions);
  const res = await response.json();
  return res;
})

export const memberCount = createAsyncThunk('user/count-member', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}team-management/count`, requestOptions);
  const res = await response.json();
  return res;
})


export const addTeamMember = createAsyncThunk('user/add-member', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}team-management/add`, requestOptions);
  const res = await response.json();
  return res;
})


export const findTeamMember = createAsyncThunk('user/find-member', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    // body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}team-management/find?${body}`, requestOptions);
  const res = await response.json();
  return res;
})


export const updateTeamMember = createAsyncThunk('user/update-member', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}team-management/update`, requestOptions);
  const res = await response.json();
  return res;
})


  export const deleteMember = createAsyncThunk('user/delete-member', async (body) => {
    // console.log('body====>', body)
    const token = body.accessToken;
    delete body.accessToken;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json","Access-Control-Allow-Origin": true },
      body: JSON.stringify(body)
    };
    token && (requestOptions.headers = {...requestOptions.headers, "access_token":token})
    console.log('requestOptions :>> ', requestOptions);
    const response = await fetch(`${process.env.REACT_APP_API_URL}team-management/delete`, requestOptions);
    const res = await response.json();
    return res;
  })



const teamSlice = createSlice({
  name: 'teamSlice',
  initialState: {
    loading: false,
    memberData: null,
    memberCount: 0,
    memberEditData: null,
    memberEditAction:false,
    fromSubmissionState: false,
    snackFlag: false,
    snackMessage: null,
    snackType: null,

  },
  reducers: {

    resetTeamFromSubmissionState(state, action) {
      if (action.payload !== undefined && action.payload.fromSubmissionState === false) {
        state.fromSubmissionState = false;
      }
    },

    resetTeamSnackbar(state, action) {
      state.snackFlag = false;
      state.snackMessage = null;
      state.snackType = null
    },

    resetEditDataMember(state, action) {
      state.memberEditData= null;
    },
    resetTeamEditState(state, action) {
      if (action.payload !== undefined && action.payload.memberEditAction === false) {
        state.memberEditAction = false;
      }
    },




  },
  extraReducers: {

    [deleteMember.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMember.fulfilled]: (state, action) => {
      state.snackFlag = true;
      state.loading = false;
      if (action.payload.status == 'success') {
        state.fromSubmissionState = true;
        state.snackMessage = action.payload.response.message;
        state.snackType = 'success';

      }
      if (action.payload.status == 'error') {
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [deleteMember.rejected]: (state, action) => {
      state.loading = false;
    },

    [memberList.pending]: (state, action) => {
      state.loading = true;
    },
    [memberList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.memberData = action.payload.response.teamData;

      }
    },
    [memberList.rejected]: (state, action) => {
      state.loading = false;
    },


    [memberCount.pending]: (state, action) => {
      state.loading = true;
    },
    [memberCount.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.memberCount = action.payload.response.teamData;
      }
    },
    [memberCount.rejected]: (state, action) => {
      state.loading = false;
    },




    [addTeamMember.pending]: (state, action) => {
      state.loading = true;
    },
    [addTeamMember.fulfilled]: (state, action) => {
      state.snackFlag = true;
      state.loading = false;
      if (action.payload.status == 'success') {
        state.teamData = action.payload.response.teamData;
        state.fromSubmissionState = true;
        state.snackMessage = action.payload.response.message
        state.snackType = 'success';

      }
      if (action.payload.status == 'error') {
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [addTeamMember.rejected]: (state, action) => {
      state.loading = false;
    },


    [findTeamMember.pending]: (state, action) => {
      state.loading = true;
      state.memberEditAction = false;
    },
    [findTeamMember.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
      state.memberEditAction = true;
        state.memberEditData = action.payload.response.teamData;
      }
    },
    [findTeamMember.rejected]: (state, action) => {
      state.loading = false;
      state.memberEditAction = false;
    },


    [updateTeamMember.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTeamMember.fulfilled]: (state, action) => {
      state.snackFlag = true;
      state.loading = false;
      if (action.payload.status == 'success') {
        state.teamData = action.payload.response.teamData;
        state.fromSubmissionState = true;
        state.snackMessage = action.payload.response.message
        state.snackType = 'success';
      }
      if (action.payload.status == 'error') {
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [updateTeamMember.rejected]: (state, action) => {
      state.loading = false;
    },


    //   [artistNameAutocomplete.pending]: (state, action) => {
    //     state.loading = true;
    //   },
    //   [artistNameAutocomplete.fulfilled]: (state, action) => {
    //     state.loading = false;
    //     if (action.payload.status == 'success') {
    //       state.artistAutocomplete = action.payload.response.artistData;
    //     }
    //   },
    //   [artistNameAutocomplete.rejected]: (state, action) => {
    //     state.loading = false;
    //   },
  }
})



export const { resetTeamFromSubmissionState,resetTeamSnackbar,resetTeamEditState, resetEditDataMember } = teamSlice.actions;
export default teamSlice.reducer;