import store from "../store";
import './layout.css'
import React, { useEffect, useRef, useState } from "react";
import { Outlet, Link, useMatch, matchPath, useParams, useLocation } from "react-router-dom";
import FrontendHeader from "./FrontendHeader/FrontendHeader";
import FrontendFooter from "./FrontendFooter/FrontendFooter";
import { useCookies } from "react-cookie";
import BackendHeader from "./BackendHeader/BackendHeader";
import { useSelector } from "react-redux";
import { Alert, LinearProgress, Snackbar } from "@mui/material";


const Layout = (props) => {
    const path = useLocation();
    const { pathname } = useLocation();
    const param = useParams();
    const userInfo = useSelector((state) => state.loginSlice.userInfo);
    const access_token = useSelector((state) => state.loginSlice.access_token);
    const isLoggedIn = useSelector((state) => state.loginSlice.isLoggedIn);
    const loader = useSelector((state) => state.loginSlice?.loading);
    const [cookies, setCookies, removeCookies] = useCookies([
        "userInfo",
        "access_token",
    ]);


    //login snack
    const snackFlag = useSelector((state) => state.loginSlice?.snackFlag);
    const snackMessage = useSelector((state) => state.loginSlice?.snackMessage);
    const loginSnackType = useSelector((state) => state.loginSlice?.snackType);

    // console.warn("Layout pathname ======>>>>>", path.pathname)
    // console.warn("param here ======>>>>>", param)




    const scrollToVideoTube = useRef(null);
    useEffect(() => {

        scrollToVideoTube.current.scrollIntoView({  position: "start" })
    }, [pathname]);



    const backendPaths = [
        "/dashboard",
        "/my-account",
        "/add-project",
        `/edit-project/${param.id}`,
        "/add-artist",
        `/edit-artist/${param.id}`,
        "/add-member",
        `/edit-member/${param.id}`
    ];

    const frontendPath = [
        "/",
        "/contact",
        "/projects",
        "/about",
        `/projects-details/${param.id}`

    ];

    useEffect(() => {
        if (isLoggedIn !== null && isLoggedIn !== undefined && isLoggedIn === true) {
            if (Object.keys(userInfo).length > 0)
                setCookies("userInfo", userInfo, { path: "/" });
            if (access_token !== null) setCookies("access_token", access_token, { path: "/" });
        }
    }, [isLoggedIn, access_token])


    return (
        <>
            <div className={backendPaths.includes(path.pathname) ? "mainBlock backendBlock" : "mainBlock"}>

                {backendPaths.includes(path.pathname) &&
                    <div className={backendPaths.includes(path.pathname) ? "sideMenu" : "turnoffMenu"}>
                        <BackendHeader />
                    </div>}



                <div ref={scrollToVideoTube} className={frontendPath.includes(path.pathname) ? "mainFullSection" : "mainSection"}>
                    {loader && <LinearProgress color="success" />}
                    {frontendPath.includes(path.pathname) && <FrontendHeader />}
                    <Outlet />
                    {frontendPath.includes(path.pathname) && <FrontendFooter />}

                </div>
            </div>
            {/* ---------- Login Snackbar -------- */}
            {snackFlag && <Snackbar open={snackFlag} autoHideDuration={3000}>
                <Alert severity={loginSnackType} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>}
            {/* ---------------------------- */}

        </>
    )
}

export default Layout;