import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const fetchProjectDetails = createAsyncThunk('project/fecthdetails', async (body) => {
  // console.log('body====>', body)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}project-management/project-single-fetch`, requestOptions);
  const res = await response.json();
  return res;
});


export const recommenedProjects = createAsyncThunk('project/recommend', async (body) => {
  // console.log('body====>', body)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}project-management/project-recommend`, requestOptions);
  const res = await response.json();
  return res;
})
  



const ProductReducer = createSlice({
    name: 'ProductReducer',
    initialState: {
      ipInfo: {},
      userInfo: {},
      access_token: null,
      _id: null,
      isLoggedIn: false,
      loading: false,
      projectDetailsData: [],
      recommedProject:[]
    },
    reducers: {
  
  
    },
    extraReducers: {
      
      [fetchProjectDetails.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchProjectDetails.fulfilled]: (state, action) => {
        state.snackFlag = true;
        state.loading = false;
        if (action.payload.status == 'success') {
            state.access_token = action?.payload?.response?.accessToken ? action.payload.response.accessToken : null;
            state.projectDetailsData = action?.payload?.response ? action.payload.response :[]
          
        }
        if (action.payload.status == 'error') {
          state.isLoggedIn = false;
          state.snackMessage = "Something Went Wrong";
          state.snackType = 'error';
        }
      },
      [fetchProjectDetails.rejected]: (state, action) => {
        state.loading = false;
      },

      
  

      [recommenedProjects.pending]: (state, action) => {
        state.loading = true;
        state.recommedProject = []
      },
      [recommenedProjects.fulfilled]: (state, action) => {
        state.snackFlag = true;
        state.loading = false;
        if (action.payload.status == 'success') {
            state.access_token = action?.payload?.response?.accessToken ? action.payload.response.accessToken : null;
            state.recommedProject = action?.payload?.response ? action.payload.response :[]
          
        } else {
          state.recommedProject =[]
          state.isLoggedIn = false;
          state.snackMessage = "Something Went Wrong";
          state.snackType = 'error';
        }
      },
      [recommenedProjects.rejected]: (state, action) => {
        state.loading = false;
        state.recommedProject = []
      },
  
    
    }
  })
  
  
  
  export const { setLogedinUserInfo, resetSnackbar } = ProductReducer.actions;
  export default ProductReducer.reducer;