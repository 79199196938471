
import './backendtopbar.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Pages/FrontEnd/Login/LoginReducer';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';

const BackendTopBar = () => {
    const _id = useSelector((state) => state.loginSlice?._id);
    const route = useLocation();
    const routeName = route.pathname.replace("/", " ");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookies, removeCookies] = useCookies([
        "userInfo",
        "access_token",
    ]);




    const logOut = () => {
        if (_id) {
            removeCookies('userInfo');
            removeCookies('access_token');
            dispatch(logout({ _id }));
        }
    }




    return (
        <>

            <div className='backendTopnavBar'>
                        
                <ul className='menu'>
                    <li> <h2> {routeName.toUpperCase()} </h2></li>
                </ul>

                <ul className='menu'>
                    
                    <li className='item'>
                        {/* <Tooltip title="Profile" placement="left-end"> */}
                        {/* <MoreVertIcon />  */}
                        <LogoutIcon onClick={() => logOut()} />
                        {/* <AccountCircleIcon /> */}
                        {/* </Tooltip> */}
                    </li>
                </ul>
            </div>
        </>
    )
}

export default BackendTopBar;