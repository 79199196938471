import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageSlider from "../../../CommonComponent /Slider/ImageSlider";
import { projectLising } from "../../Backend/Projects/ProjectReducer";
import "./home.css"
import TopBanner from "./TopBanner";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { format } from "date-fns";
 

const Home = () => {
    var today = Math.floor(new Date().getTime())
    
    const dispatch = useDispatch();
    const projectListingData = useSelector((state)=> state.productSlice?.projectListingData? state.productSlice?.projectListingData : [])
    const loader = useSelector((state)=> state.productSlice?.loading? state.productSlice?.loading : false)

    const filterProj = projectListingData.filter((x,i)=> i<3)

    useEffect(() => {
        dispatch(projectLising({}))
    },[])

    return (
        <>
            <div className="main_container">

      
            
                    
                <>
                    <div className="tpBanner-section">

                       {loader && <div className="loader_section"><CircularProgress color="error" /></div>}

                       
              
                        <TopBanner slidedata={projectListingData} />

                        
                        <div className="branding">
                            <div className="content">
                                <h1> DREAMLINER ENTERTAINMENT</h1>
                                <p> Where Creativity Meets Innovations In The World Of Filmmaking</p>
                            </div>
                        </div>


                    </div>

                    
                    <div className="second_section">
                        <div className="recent_projectWrapper">
                            <div className="heading">
                                <h1> RECENT PROJECTS</h1>
                                <p>  Discover Our Showcase of Recent Projects. </p>
                                </div>
                                
                            {loader && <div className="loader_section"><CircularProgress color="error" /></div>}
                            
                            {!loader && projectListingData.length > 0 ?
                              (<div className="slider_section">
                                <ImageSlider slidedata={projectListingData} />
                            </div>) : !loader && projectListingData.length === 0 && <div className="nodata_secion"> <p> No Data Found!</p></div> } 
                        </div>
                    </div>
                </> 
                 
            
            </div>
        </>
    );

}

export default Home;