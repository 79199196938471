import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import './login.css';
import logo from '../../../img/logo.png'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, Button, LinearProgress, Snackbar, TextField, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { getipInfo, login, resetSnackbar } from "./LoginReducer";
import store from "../../../store";
import { Helmet } from "react-helmet";

store.dispatch(getipInfo());


function Login() {
    const snackFlag = useSelector((state) => state.loginSlice?.snackFlag);
    const snackMessage = useSelector((state) => state.loginSlice?.snackMessage);
    const snackType = useSelector((state) => state.loginSlice?.snackType);
    const loading = useSelector((state) => state.loginSlice?.loading);

    const { control, watch, setValue, getValues, handleSubmit, formState: { errors }, clearErrors } = useForm();
    const [passVisible, setPassVisible] = useState(false)
    const [data, setData] = useState({})
    const dispatch = useDispatch();
    const ipInfo = useSelector((state) => state.loginSlice?.ipInfo);
    const [errorMessages, setErrorMessages] = useState({
        required: 'This Field is Required',
        validate: "Please Enter Password",
        pattern: "Invalid Email Address"
    });
    const isValidPassword = (state) => state !== '' ? true : false;

    const onSubmit = () => {
        console.log('onSubmit hittt :>> ');
        dispatch(login({ ...data, ipInfo }))
    }

    function tooglePassV(val) {
        setPassVisible(val)
    }


    useEffect(() => {
        console.log('data :>> ', data);
    }, [data])

    useEffect(() => {
        setTimeout(() => {
            store.dispatch(resetSnackbar());
        }, 3000);
    }, [snackFlag])



    return (
        <>

                <Helmet>
                    <title>Login</title>
                    <meta name="description" content="Login Page"></meta>
                </Helmet>
            <div className="loginmain_wrapper">
                <div className='wrapper'>

                    <img src={logo} className='logo' alt='Logo' />

                    <form>
                        <div className='block'>
                            <Controller
                                control={control}
                                name="email"
                                rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                                render={() => (
                                    <TextField
                                        id="outlined-basic"
                                        value={data?.email}
                                        label="Email ID"
                                        className='flds'
                                        variant="outlined"
                                        onChange={(event) => {
                                            setValue('email', event.target.value?.trim())
                                            setData({ ...data, 'email': event.target.value })
                                            clearErrors('email')
                                        }}
                                        error={Object.keys(errors).length > 0 && errors.email != null}
                                        helperText={errors.email && errorMessages[errors.email.type]}
                                    />
                                )}
                            />
                        </div>

                        <div className='block'>
                            <Controller
                                control={control}
                                name="password"
                                rules={{ required: true, validate: isValidPassword }}
                                render={() => (
                                    <TextField
                                        id="outlined-basic"
                                        label="Password"
                                        className='flds'
                                        variant="outlined"
                                        value={data?.password}
                                        onChange={(event) => {
                                            setValue('password', event.target.value?.trim())
                                            setData({ ...data, 'password': event.target.value })
                                            clearErrors('password')
                                        }}
                                        error={Object.keys(errors).length > 0 && errors.password != null}
                                        helperText={errors.password && errorMessages[errors.password.type]}
                                    />
                                )}
                            />
                            {passVisible === false ? <Tooltip title="Hide" placement="right-start"><RemoveRedEyeIcon className='toogle_icon' onClick={() => tooglePassV(true)} /></Tooltip> : <Tooltip title="Show" placement="right-start"><VisibilityOffIcon className='toogle_icon' onClick={() => tooglePassV(false)} /> </Tooltip>}

                        </div>
                        <Button variant="contained" className='btn' onClick={handleSubmit(onSubmit)}>Login</Button>
                        {loading && <LinearProgress />}
                    </form>


                </div>
            </div>
            {/* ---------- Snackbar -------- */}
            {/* {snackFlag && <Snackbar open={snackFlag} autoHideDuration={3000}>
                    <Alert severity={snackType} sx={{ width: '100%' }}>
                        {snackMessage}
                    </Alert>
                 </Snackbar>} */}
            {/* ---------------------------- */}
        </>
    )
}
export default Login