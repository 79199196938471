import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./Pages/FrontEnd/Login/LoginReducer";
import productSlice from "./Pages/Backend/Projects/ProjectReducer";
import artistSlice from "./Pages/Backend/ArtistDirectory /ArtistReducer";
import teamSlice from "./Pages/Backend/TeamManagement/TeamReducer"
import aboutPageSlice from './Pages/FrontEnd/About/AboutReducer'
import ProductReducer from './Pages/FrontEnd/ProjectDetails/ProjectReducer'


export default configureStore({
    reducer: {
        loginSlice,
        productSlice,
        artistSlice,
        teamSlice,
        aboutPageSlice: aboutPageSlice,
        ProductReducer: ProductReducer
    },
});