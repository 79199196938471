import { LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { projectLising } from '../../Backend/Projects/ProjectReducer';
import './project.css'
import ProjectSlider from './ProjectSlider';

function Projects() {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectListingData = useSelector((state)=> state.productSlice?.projectListingData? state.productSlice?.projectListingData : [])
    const loader = useSelector((state)=>state.productSlice?.loading? state.productSlice?.loading : false)

    const featureFilms = projectListingData.filter((x) => x.category === "feature_films" && x) 
    const short_films = projectListingData.filter((x) => x.category === "short_films" && x)

    
    
  
    useEffect(() => {
        dispatch(projectLising({}))
        window.scrollTo(0, 0)
    },[])

    return (
        <>
            <div className="project_mainWrapper">
                <div className="project_main">

                    
                     {loader && <div className='loaderBlock'> <LinearProgress className='loader' /> </div>}

                    {featureFilms.length > 0 &&
                        <div className="block">
                            <h1> Feature Films</h1>

                            <div className='container'>

                          
                                {loader && <LinearProgress className='loader' />}

                                {loader === false && featureFilms?.length > 4 ? <ProjectSlider slidedata={featureFilms} /> :
                                
                                    loader === false && featureFilms?.length < 5 ?
                               
                                        <div className='manual_images'>
                                    
                                            {featureFilms.map((x) => {
                                                return (
                                                    x.category === "feature_films" && x.status === true &&
                                                    (<div className='cards' onClick={() => navigate('/projects-details/' + x._id)}>
                                                        <img src={x.poster_verticle} alt="Project Poster" className='card-img' onClick={() => navigate('/projects-details/' + x._id)} />
                                                    </div>))
                                        
                                    
                                            })}
                                        </div>
                                        : loader === false && featureFilms.length === 0 && <p className='dataPara'>  Data Not Available </p>}
                            
                            </div>

                        </div>}

                    {short_films.length > 0 &&
                        <div className="block">
                            <h1> Short Films</h1>

                            <div className='container'>

                                {short_films.length > 4 ? <ProjectSlider slidedata={short_films} /> :
                                    
                                    short_films.length < 5 ?
                                        
                                       <div className='manual_images'>
                                    
                                        {short_films.map((x) => {
                                            return (
                                                x.category === "short_films" && x.status === true &&
                                                (<div className='cards' onClick={()=> navigate('/projects-details/'+x._id)}>
                                                    <img src={x.poster_verticle} alt="Project Poster" className='card-img' onClick={()=> navigate('/projects-details/'+x._id)}/>
                                                   
                                                </div>))
                                        })}
                                            
                                        </div>
                            
                                        : short_films.length === 0 && <p className='dataPara'>  Data Not Available </p>}
                            </div>

                        </div>}
                </div>
            </div>
        </>
    );
}

export default Projects;