import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './imageuploader.css'
import moment from 'moment';
import { Badge, Button, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import LazyImage from '../LazyLoading/LazyImage';

const isValidBase64 = (str) => {
  try {
    return btoa(atob(str)) === str;
  } catch (e) {
    return false;
  }
};

const base64ToBlob = (base64String, type = 'image/jpeg') => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  return new Blob([new Uint8Array(byteArrays)], { type });
};

const base64ToFormData = (base64String, fieldName) => {
  const formData = new FormData();
  const blob = base64ToBlob(base64String);

  formData.append(fieldName, blob);

  return formData;
};

const ImageUploader = ({ urls = [], setUrls, single = false ,imgSize }) => {
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [crop, setCrop] = useState({
    // unit: '%',
    // width: 30,
    // aspect: 16 / 9
  });

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles)
    setImage(URL.createObjectURL(acceptedFiles[0]));
  };

  const handleCrop = () => {
    if (cropper && typeof cropper.getCroppedCanvas === 'function') {
      setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    }
  };

  let cropper;

  const handleUpload = async () => {
    const blobData = base64ToBlob(croppedImage.split('base64,')[1]);

    try {
      setUploading(true)
      const reqBody = {
        "bucket": process.env.REACT_APP_UPLOAD_BUCKET,
        "path": "uploaded-files/",
        "name": moment().valueOf().toString() + '_' + files[0].name,
        "type": files[0].type
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(reqBody),
      };
      const response = await fetch(`${process.env.REACT_APP_UPLOAD_BASE_URL}api/request-upload-url`, requestOptions)
      const result = await response.json();
      console.log('File uploaded successfully: ', result);

      if (!result.results.uploadURL) return

      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': files[0].type
      }
      const uploadImgRes = await fetch(result.results.uploadURL, {
        method: "PUT",
        headers: headers,
        body: blobData
      })
      setUrls((prev) => single == true ? [result.results.filePath] : [result.results.filePath, ...prev])
      setUploading(false)
      setFiles(null)
      setImage(null)
      /////////////////////////////////////////////////////////////////////////////////
      console.log('uploadImage', uploadImgRes)
    } catch (err) {
      setUploading(false)
      console.error('Error uploading file: ', err);
    }
  }

  const handleDelete = (url, index) => {
    setUrls((prev) => {
      const temp = [...prev]
      temp.splice(index, 1)
      return temp
    })
  }

  const handlePreview = (url) => {
    window.open(url, '__blank')
  }

  const handleCancel = () => {
    setImage(null)
    setFiles(null)
    setCroppedImage(null)
    setUploading(false)
    // setCrop({
    //   unit: '%',
    //   width: 30,
    //   aspect: 16 / 9
    // })
  }

  useEffect(() => {

    return () => {
      setImage(null)
      setFiles(null)
      setCroppedImage(null)
      setUploading(false)
      // setCrop({
      //   unit: '%',
      //   width: 30,
      //   aspect: 16 / 9
      // })
    }
  }, [])




  return (
    <div className='main_container'>
      <div className='action_container'>
        <Dropzone onDrop={handleDrop} className="drop_zone">
          {({ getRootProps, getInputProps }) => (
            <div className="drag-drop-box"  {...getRootProps()}>
              <input {...getInputProps()} />
              <span>Drag and drop image here or click to upload image, image size {imgSize }</span>
            </div>
          )}
        </Dropzone>
        {image && (
          <>
            <div className='cropper_container'>
              {croppedImage && (
                <div className='img_preview'>
                  <LazyImage src={croppedImage} alt="Cropped" />
                  {/* <img src={croppedImage} alt="Cropped" /> */}
                </div>
              )}
              <div className='img_btn_preview'>
                <Button variant='contained' color='success' onClick={handleUpload} disabled={uploading}>{!uploading ? <CloudUploadIcon /> : <CircularProgress color='success' size="2rem" />}</Button>
                <Button variant='contained' color='success' onClick={handleCancel} disabled={uploading}>{!uploading ? <CancelIcon /> : <CircularProgress color='success' size="2rem" />}</Button>
              </div>
              <Cropper
                src={image}
                style={{ height: "auto", width: 'auto' }}
                aspectRatio={crop.aspect}
                guides={false}
                crop={handleCrop}
                onInitialized={(instance) => {
                  cropper = instance;
                }}
              />
            </div>

          </>
        )}
      </div>

      <div className='image_view_container'>
        {(urls && Array.isArray(urls) && urls.length > 0) ?
          urls.map(((url, index) =>
            <Badge badgeContent={index + 1} color="success">
              <div className='img_preview_container'>
                <div className='img_preview' >
                  <LazyImage src={url} alt={url} />
                </div>
                <div className='action_btn_container'>
                  <DeleteIcon color='error' onClick={() => handleDelete(url, index)} />
                  <RemoveRedEyeIcon color='info' onClick={() => handlePreview(url)} />
                </div>
              </div>
            </Badge>
          )
          ) : (
            <p>No Images to Preview</p>
          )}
      </div>
    </div>
  );
};

export default ImageUploader;
