
import './frontendfooter.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import InstagramIcon from '@mui/icons-material/Instagram';

const FrontendFooter = () => {

    const navigate = useNavigate();

    return (
        <>
            
            <div className='socialMedia_aaction'>
                <div className='heading'>
                    <h1> ON SOCIAL NETWORKS </h1>
                </div>
                <div className='social_icons'>
                    <FacebookIcon className='icons' onClick={()=>window.open("https://www.facebook.com/dreamliner.co.in/", '_blank')} />
                    <InstagramIcon className='icons' onClick={() => window.open("https://www.instagram.com/dreamliner_entertainment/", '_blank')} />
                    <YouTubeIcon className='icons' onClick={() => window.open("https://www.youtube.com/@dreamlinerentertainment7641", '_blank')} /> 
                </div>
            </div>


            <div className='frontendfooter_wrapper'>
                <div className='footer'>
                    
                    <div className='block first_block'>
                        <h2> DREAMLINER ENTERTAINMENT</h2>
                        <p> Dreamliner Entertainment, an illustrious production company, based in Kolkata, was founded by the visionary Arindam Bhattacharya. Passionate about films, Arindam stands as the driving force behind all three of his cinematic masterpieces. He has been constantly working on adding new feathers to the hat, under the directory of films bannered by Dreamliner Entertainment.</p>

                      
                    </div>

                    <div className='block second_block'>
                        <h2> QUICK LINKS </h2>
                        <ul>
                            <li onClick={()=> navigate('/')}> HOME</li>
                            <li onClick={()=> navigate('/projects')}> PROJECTS</li>
                            <li onClick={()=> navigate('/about')}> ABOUT US</li>
                            <li onClick={() => navigate('/contact')}> CONTACT US</li>
                        </ul>
                    </div>

                    <div className='block third_block'>
                        <h2> CONTACT INFORMATION</h2>
                       
                        <div className=''>
                        <h2> Registered Office</h2>

                            <p>
                               C1102 Bestech Sanskruti Sector 92 Gurgaon 122505
                            </p>

                        </div>

                    
                        <div className=''>
                            <h2> Branch Office</h2>
                    
                            <div className=''>
                                <h4> Canada</h4>
                                <p> 365 Pioneer Drive Kitchener Ontario - N2P 2A6 </p>
                           </div>

                            <div className=''>
                                <h4> Kolkata</h4>
                                <p> 15 Mayfair Road Kolkata 700019 </p>
                           </div>

                        </div>
                        

                    </div>

                </div>
            </div>

            <div className='after_footersection'>
                    <p> Copyright &copy; <a onClick={()=> navigate('/admin-login')}>DREAMLINER ENTERTAINMENT</a> | Design & Developed By InfoTechEdge</p>
            </div>

        </>
    )
}

export default FrontendFooter;