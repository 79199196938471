import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, TextField, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { artistNameAutocomplete } from './Pages/Backend/ArtistDirectory /ArtistReducer';
const NoPage = () => {
    const [value, setValue] = useState([]);
    const [query, setQuery] = useState();

    const artistList = useSelector((state) => state.artistSlice?.artistAutocomplete);
    const dispatch = useDispatch();

    useEffect(() => {
        if (query) {
            const getData = setTimeout(() => {
                dispatch(artistNameAutocomplete(query))

            }, 2000)

            return () => clearTimeout(getData)
        }
    }, [query])


    // export default function AutocompleteControlled() {
    // const classes = useStyles();


    console.log("value: ", value);

    return (
        <div className="main-block">
            {/* <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                multiple
                id="tags-filled"
                options={artistList.map((option) => option.name)}
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        label="Users"
                        placeholder="Search"
                        onChange={(e) => {
                            setQuery(e.target.value);

                            // onChange(e);
                        }}
                    />
                )}
            /> */}
            No Page
        </div>
    );
}
//   return (
//     <div>NoPage</div>
//   )
// }

export default NoPage