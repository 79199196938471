import Slider from "react-slick";
// import store from "../../store";
import React, { useEffect, useRef, useState } from "react";
import './slider.css'
import { Button } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ImageSlider = (props) => {

    const navigate = useNavigate()

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,  
        initialSlide: 0,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1430,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },

            {
                breakpoint: 1147,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
            },
            
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },

            
              
          ]
    };

    return (
        <>
            <div className="slider">

                {/* ------- image slider --------- */}
                <Slider {...settings}>

                   
                   
                    {props.slidedata.map((x) => {
                       if(x.status===true)
                        return (
                            <div key={x._id} > 
                                <div className="wrapper" onClick={()=> navigate('/projects-details/'+x._id)}>
                                    <div className="subwrap">
                                       <div className="imgg">
                                            <img src={x.poster_verticle} className="img" alt="" onClick={()=> navigate('/projects-details/'+x._id)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
  
                })}
                  
                </Slider >

                {/* ----------------- */}



            </div >
        </>
    )
}

export default ImageSlider;
