import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


//======= projectAddUpdate =======//
export const addUpdateProject = createAsyncThunk('project-management/add-edit', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  // token && (requestOptions.headers = {...requestOptions.headers, "access_token":token})

  const response = await fetch(`${process.env.REACT_APP_API_URL}project-management/addedit`, requestOptions);
  const res = await response.json();
  return res;
})
// ============================ //


// ========= fetchProjectList ========= //
export const projectLising = createAsyncThunk('project-management/listing', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  // token && (requestOptions.headers = {...requestOptions.headers, "access_token":token})

  const response = await fetch(`${process.env.REACT_APP_API_URL}project-management/project-listing`, requestOptions);
  const res = await response.json();
  return res;
})
// ==================================== //



// ========= projectStatusChange ========= //
export const projectStatusChange = createAsyncThunk('project-management/status-chnage', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  // token && (requestOptions.headers = {...requestOptions.headers, "access_token":token})

  const response = await fetch(`${process.env.REACT_APP_API_URL}project-management/status-change`, requestOptions);
  const res = await response.json();
  return res;
})
// ==================================== //



// ========= projectListingCount ========= //
export const projectListingCount = createAsyncThunk('project-management/listing-count', async (body) => {
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  // token && (requestOptions.headers = {...requestOptions.headers, "access_token":token})

  const response = await fetch(`${process.env.REACT_APP_API_URL}project-management/project-listing-count`, requestOptions);
  const res = await response.json();
  return res;
})
// ==================================== //

// ========= project Find ========= //
export const findProject = createAsyncThunk('project-management/find', async (body) => {
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "Get",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    // body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })

  const response = await fetch(`${process.env.REACT_APP_API_URL}project-management/find?${body}`, requestOptions);
  const res = await response.json();
  return res;
})
// ==================================== //





const productSlice = createSlice({
  name: 'productSlice',
  initialState: {
    loading: false,
    loader: false,
    projectData: null,
    actionsuccessedit:false,
    sucess: false,
    projectListingData: [],
    actionsuccess: false,
    statusUpdate: false,
    fromSubmissionState: false,
    count: 0,
    snackFlag: false,
    snackMessage: null,
    snackType: null,
  },
  reducers: {
    resetProjectSubmissionState(state, action) {
      if (action.payload !== undefined && action.payload.fromSubmissionState === false) {
        state.fromSubmissionState = false;
      }
    },
    resetProjectEditState(state, action) {
      if (action.payload !== undefined && action.payload.actionsuccessedit === false) {
        state.actionsuccessedit = false;
      }
    },

    resetProjectSnackbar(state, action) {
      state.snackFlag = false;
      state.snackMessage = null;
      state.snackType = null
      state.actionsuccess = false;
    },
    resetProjectEditData(state, action) {
      state.projectData = null;

    },
    resestData(state, action) {
      state.projectListingData = [];
    }


  },
  extraReducers: {

    //=========== find project ===========
    [findProject.pending]: (state, action) => {
      state.loader = true;
      state.loading = true;
      state.actionsuccessedit = false;
      state.projectData = null;
    },
    [findProject.fulfilled]: (state, action) => {
      state.loader = false;
      state.loading = false;
      state.actionsuccessedit = true;
      if (action.payload.status == 'success') {
        state.projectData = action.payload?.response?.projectData;
      }
    },
    [findProject.rejected]: (state, action) => {
      state.loader = false;
      state.actionsuccessedit = false;
      state.loading = false;
    },

    // =========== addUpadateProject ======== //
    [addUpdateProject.pending]: (state, action) => {
      state.loading = true;
      state.sucess = false;
      state.actionsuccess = false;
      state.loader = true;

    },
    [addUpdateProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.snackFlag = true;
      if (action.payload.status == 'success') {
        state.sucess = true
        state.loader = false;
        state.fromSubmissionState = true;
        state.snackMessage = action.payload.response.message ? action.payload.response.message : "Added Successful";
        state.actionsuccess = true;
        state.snackType = 'success';
      }
      if (action.payload.status == 'error') {
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [addUpdateProject.rejected]: (state, action) => {
      state.loading = false;
      state.sucess = false;
      state.loader = false;

    },
    // ===================================== //



    // ========= projectListing ========== //
    [projectLising.pending]: (state, action) => {
      state.loading = true;
      state.sucess = false;
      state.projectListingData = []
      state.loader = true;

    },
    [projectLising.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.sucess = true;
        state.loader = false;
        state.projectListingData = action.payload?.response ? action.payload.response : []
      }
    },
    [projectLising.rejected]: (state, action) => {
      state.loading = false;
      state.sucess = false;
      state.loader = false;

    },
    // ====================================== //


    // ========= projectStatus Chnage ========== //
    [projectStatusChange.pending]: (state, action) => {
      state.snackFlag = false;
      state.sucess = false;
      state.loader = true;
      state.actionsuccess = false;

    },
    [projectStatusChange.fulfilled]: (state, action) => {

      state.snackFlag = true;
      state.loader = false;
      if (action.payload.status == 'success') {
        state.sucess = true
        state.actionsuccess = true;
        state.snackType = 'success';
        state.snackMessage = action.payload.message

      }
      if (action.payload.status == 'error') {
        state.snackMessage = action.payload.message;
        state.snackType = 'error';
      }
    },
    [projectStatusChange.rejected]: (state, action) => {

      state.sucess = false
      state.loader = false;
      // state.actionsuccess = true;
      state.snackFlag = false;

    },
    // ====================================== //




    // ========= projectListing Count ========== //
    [projectListingCount.pending]: (state, action) => {
      state.sucess = false;
      state.loader = true;
      state.count = 0;

    },
    [projectListingCount.fulfilled]: (state, action) => {
      if (action.payload.status == 'success') {
        state.sucess = true
        state.count = action.payload?.response ? action.payload.response : 0;
      }
    },
    [projectListingCount.rejected]: (state, action) => {
      state.sucess = false
      state.loader = false;
      state.count = 0;
    },
    // ====================================== //

  }
})



export const { resetProjectSubmissionState,resetProjectEditState, resetProjectSnackbar, resetProjectEditData, resestData } = productSlice.actions;
export default productSlice.reducer;