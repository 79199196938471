

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const TeamMemberListing = createAsyncThunk('aboutpage/member-list', async (body) => {
    // console.log('body====>', body)
    const token = body.accessToken;
    delete body.accessToken;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json","Access-Control-Allow-Origin": true },
      body: JSON.stringify(body)
    };
    // token && (requestOptions.headers = {...requestOptions.headers, "access_token":token})
    // console.log('requestOptions :>> ', requestOptions);
    const response = await fetch(`${process.env.REACT_APP_API_URL}team-management/list`, requestOptions);
    const res = await response.json();
    return res;
  })





const aboutPageSlice = createSlice({
    name:'aboutPageSlice',
    initialState:{
    loading:false,
    teamListingData:[]

    },
    reducers:{

    },
    extraReducers:{
          [TeamMemberListing.pending]: (state, action) => {
            state.loading = true;
          },
          [TeamMemberListing.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status == 'success') {
              state.teamListingData = action.payload?.response.teamData? action.payload.response.teamData : [];
            }
          },
          [TeamMemberListing.rejected]: (state, action) => {
            state.loading = false;
          },

    }
})



export const {  } = aboutPageSlice.actions;
export default aboutPageSlice.reducer;