import Slider from "react-slick";
// import store from "../../store";
import React, { useEffect, useRef, useState } from "react";
import './topbanner.css'
import { Button } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { format } from "date-fns";

const TopBanner = (props) => {

    const navigate = useNavigate()

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1430,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },

            {
                breakpoint: 1147,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
            },
            
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },

            
              
          ]

    };
    
    var today = Math.floor(new Date().getTime())

  
    
    return (
        <>
            <div className="sliderTopBanner">

                {/* ------- image slider --------- */}
                <Slider {...settings}>
       
                    {props.slidedata.map((x) => {
                    if(x.status===true && x.featured===true)
                        return (
                            <div key={x._id} > 
                                <div className="Top_wrapper" onClick={()=> navigate('/projects-details/'+x._id)}>
                                    <div className="Top_subwrap">
                                            <div className="Slideimgg">
                                                <div className="box">
                                                    <img src={x.poster_verticle} className="img" alt="" onClick={() => navigate('/projects-details/' + x._id)} />
                                                </div>
                                            </div>
                                            {/* <div className="desc">
                                                <div className="block">
                                                    <ul>
                                                        <li> {x.actors.name}</li>
                                                    </ul>
                                                </div>
                                                <div className="block">
                                                     {x.trailer_link!=="NA" ? <p className="link"> WATCH TRAILER ON &nbsp; <YouTubeIcon className="yt" onClick={() => window.open(x.trailer_link, '_blank')} /> </p> : <p> TRAILER COMING SOON!!</p> }
                                                </div>

                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        );

                    })}

                </Slider >

                {/* ----------------- */}



            </div >
        </>
    )
}

export default TopBanner;
